<template>
  <Tabs v-model="bilanTabs" layout="simple" v-if="helperService.userHasPermission('essaifichevalidation_oshow')">
    <!-- Bilan de l'expérimentation -->
    <Tab title="Bilan de l'expérimentation">
      <Container>
        <h4>Déviations par rapport au protocole et/ou aux modes opératoires</h4>
        <h5>Dispositif expérimental</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Dispositif expérimental" :value="helperService.displayBoolean(bilan.dispositif)" />
        <div v-show="bilan.dispositif">
          <KeyValue table label="Précisions" :value="bilan.dispositif_precisions" />
          <KeyValue table label="Évaluations" :value="bilan.dispositif_evaluation" />
        </div>

      </div>

      <Container>
        <h5>Applications au champ</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Applications au champ" :value="helperService.displayBoolean(bilan.appchamp)" />
        <div v-show="bilan.appchamp">
          <KeyValue table label="Précisions" :value="bilan.appchamp_precisions" />
          <KeyValue table label="Évaluations" :value="bilan.appchamp_evaluation" />
        </div>
      </div>

      <Container>
        <h5>Notations et observations</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Applications au champ" :value="helperService.displayBoolean(bilan.notations)" />
        <div v-show="bilan.notations">
          <KeyValue table label="Précisions" :value="bilan.notations_precisions" />
          <KeyValue table label="Évaluations" :value="bilan.notations_evaluation" />7
        </div>
      </div>

      <Container>
        <h5>Analyse statistiques</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Applications au champ" :value="helperService.displayBoolean(bilan.stats)" />
        <div v-show="bilan.stats">
          <KeyValue table label="Précisions" :value="bilan.stats_precisions" />
          <KeyValue table label="Évaluations" :value="bilan.stats_evaluation" />
        </div>
      </div>

      <Container>
        <h4>Incidents pendant le cycle de la culture</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Etat végétatif de la culture, sélectivité, autres" :value="bilan.etatvegetatif" />
      </div>

      <Container>
        <h5>Température</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Stress lié au climat" :value="helperService.displayBoolean(bilan.stressclimat_temp)" />
        <div v-show="bilan.stressclimat_temp">
          <KeyValue table label="Pré floraison" :value="bilan.stress_temp_pre?.valeur" />
          <KeyValue table label="Floraison" :value="bilan.stress_temp?.valeur" />
          <KeyValue table label="Post floraison" :value="bilan.stress_temp_post?.valeur" />
          <KeyValue table label="Commentaires" :value="bilan.stress_temp_commentaires" />
        </div>
      </div>

      <Container>
        <h5>Pluviométrie</h5>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Stress lié au climat" :value="helperService.displayBoolean(bilan.stressclimat_pluvio)" />
        <div v-show="bilan.stressclimat_temp">
          <KeyValue table label="Pré floraison" :value="bilan.stress_pluvio_pre?.valeur" />
          <KeyValue table label="Floraison" :value="bilan.stress_pluvio?.valeur" />
          <KeyValue table label="Post floraison" :value="bilan.stress_pluvio_post?.valeur" />
          <KeyValue table label="Commentaires" :value="bilan.stress_pluvio_commentaires" />
        </div>
      </div>

      <Container>
        <h4>Rendements</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="CV rendement" :value="bilan.cv_rendement" />
        <KeyValue table label="Rendement moyen" :value="bilan.rendement_moyen" />
      </div>

      <Container>
        <h4>Avis sur les résultats et enseignements de l'essai</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Commentaires" :value="bilan.avis_resultats" />
      </div>
    </Tab>

    <!-- Validations -->
    <Tab title="Validation" v-if="helperService.userHasPermission('essaifichevalidation_oshow')">
      <Container>
        <h4>Validation statistique de l'essai</h4>
      </Container>
      <div class="key-values margin-bottom">
        <KeyValue table label="Du point de vue statistique, validez vous cet essai?"
          :value="helperService.displayBoolean(validation.stat_validation)" />
        <KeyValue table label="Commentaires" :value="validation.stat_commentaire" />
      </div>

      <Container>
        <h4>Validation agronomique de l'essai</h4>
      </Container>
      <div class="key-values key-values--page-bottom">
        <KeyValue table label="L'essai répond-il à l'objectif de départ ?"
          :value="validation.accord_objectif?.valeur" />
        <KeyValue table label="Abandon de l'essai" :value="helperService.displayBoolean(validation.abandone_essai)" />
        <KeyValue table label="Du point de vue agronomique, validez vous cet essai?"
          :value="helperService.displayBoolean(validation.validation_agro)" />
        <KeyValue table label="Commentaires" :value="validation.commentaires" />
        <KeyValue table label="Date" :value="helperService.displayDate(validation.validation_date)" />
        <KeyValue table label="Trigramme" :value="validation.validation_trigramme" />
        <KeyValue table label="Clôture" :value="helperService.displayBoolean(validation.cloture)" />
      </div>
    </Tab>

    <!-- Validation -->
    <Tab title="Statut de l'essai SIDEP" v-if="helperService.userHasPermission('essaifichestatutsidep_oshow')">
      <Container>
        <h4>Informations de statut</h4>
      </Container>
      <div class="key-values key-values--page-bottom">
        <KeyValue table label="Déclaration de l’essai" :value="sidep.statut_declaration?.valeur" />
        <KeyValue table label="Date de déclaration de l’essai"
          :value="helperService.displayDate(sidep.declaration_date)" />
        <KeyValue table label="Clôture de l’essai" :value="sidep.statut_cloture?.valeur" />
        <KeyValue table label="Date de clôture de l’essai" :value="helperService.displayDate(sidep.cloture_date)" />
      </div>
    </Tab>
  </Tabs>
</template>

<script>
import { ref } from 'vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Tab from '@/components/base/Tab.vue'
import Tabs from '@/components/base/Tabs.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'Parcel',
  components: {
    Container,
    Tabs,
    Tab,
    KeyValue,
  },
  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },
  setup() {
    const bilanTabs = ref(0)
    return {
      bilanTabs,
    }
  },
  data() {
    return {
      bilan: {},
      validation: {},
      sidep: {},
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.emitter.emit('open-loader')
      Promise.all([
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/bilan`)
          .then((response) => {
            const [first] = response.data
            this.bilan = first
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/validation`)
          .then((response) => {
            const [first] = response.data
            this.validation = first
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
        this.fetchService
          .get(`essai/${this.$route.params.id}/fiche/statut_sidep`)
          .then((response) => {
            const [first] = response.data
            this.sidep = first
          },
          (error) => {
            this.emitter.emit('close-loader')
            this.formService.handleApiError(error)
          }),
      ]).finally(() => {
        this.emitter.emit('close-loader')
      })
    },
  },
}
</script>
